<div class="modal">
    <div class="modal-dialog mt-0">
        <div class="modal-content sm pt-3">
            <div class="modal-header mb-2">
                <div class="modal-title" translate>{{ title }}</div>
                <i class="icon-close cp" style="font-size: 12px" (click)="emitEvent.emit(false)"></i>
            </div>
            <div class="modal-body">
                <p *ngFor="let item of list">
                    {{ title === 'entities.entity1' ? item.entityName : title === 'access.users.role' ?
                    item.roleName : title === 'access.users.region' ? item.regionName :
                    title === 'common.country' ? item.countryName : item.l2ServiceName }}
                </p>
            </div>
        </div>
    </div>
</div>