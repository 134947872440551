import { Component, Input } from '@angular/core';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { StatusCodeService } from '../../../../services/status-code/status-code.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime } from 'rxjs';
import { auditTracking } from '../../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../../services/shared/shared.service';

@Component({
    selector: 'app-work-schedule',
    templateUrl: './work-schedule.component.html',
    styleUrl: './work-schedule.component.scss',
    standalone: false
})
export class WorkScheduleComponent {
  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  description: string = '';
  workScheduleCode: string = '';
  workschedulesList: any[] = [];
  hexLoader: boolean = false;
  selectedIds: any[] = [];
  editableRowIndex: number = -1;
  selectedRow: any = {};
  isConfirmPopup: string = '';
  isLoader: boolean = true;
  duplicateWorkSchedule: boolean = false;
  workScheduleSubject = new Subject<any>();
  fieldType: string = '';

  constructor(private entitiesService: EntitiesService, private showStatusCode: StatusCodeService, private toastr: ToastrService,
    private translate: TranslateService, private sharedService: SharedService) {
    this.workScheduleCheck();
  }

  ngOnInit() {
    this.sharedService.tracker();
    this.getWorkSchedules();
  }

  saveupdateWorkSchedule(workSchedule?: any) {
    this.toastr.clear();
    this.hexLoader = true;
    let body = {
      entityId: this.selectedEntity?.entityId,
      workScheduleCode: workSchedule ? workSchedule.workScheduleCode.trim() : this.workScheduleCode.trim(),
      workScheduleDescription: workSchedule ? workSchedule.workScheduleDescription.trim() : this.description.trim(),
      workScheduleId: workSchedule ? workSchedule.workScheduleId : null
    };
    this.entitiesService.addWorkSchedule(body).subscribe({
      next: res => {
        this.hexLoader = false;
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', workSchedule ? 'common' : 'entities.workSchedules');
        }

        if (workSchedule) {
          let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editWorkSchedule');

          auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
            this.translate.instant('auditTrail.entities.entityDetail'),
            'Edit',
            description)

        } else {
          let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.addWorkSchedule');

          auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
            this.translate.instant('auditTrail.entities.entityDetail'),
            'Add',
            description)
        }

        this.resetWorkSchedule();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  getWorkSchedules() {
    this.entitiesService.getWorkSchedules(this.selectedEntity?.entityId).subscribe({
      next: res => {
        if (res.data) {
          this.workschedulesList?.push(...res.data);
        }
        this.disableloader();

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewWorkSchedule');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          description)

      },
      error: (error) => {
        this.disableloader();
      }
    })
  }

  deleteWorkSchedule(id?: any) {
    this.isConfirmPopup = 'delete'
    if (id) {
      this.selectedIds = [id];
    } else {
      this.selectedIds = this.workschedulesList.map(it => { return it.workScheduleId });
    }
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.isConfirmPopup === 'edit') {
        this.resetEditRow();
      } else {
        this.deleteSelectedWorkSchedule();
      }
    }
    this.isConfirmPopup = '';
  }

  resetWorkSchedule() {
    if (this.editableRowIndex === -1) { this.workScheduleCode = ''; }
    this.description = '';
    this.editableRowIndex = -1;
    this.workschedulesList = [];
    this.isLoader = true;
    this.duplicateWorkSchedule = false;
    this.getWorkSchedules();
  }

  deleteSelectedWorkSchedule() {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "workScheduleIds": this.selectedIds,
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.deleteWorkSchedule(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.workSchedules');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.deleteWorkSchedule');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          'Delete',
          description)

        this.resetWorkSchedule();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  editRow(index: number) {
    this.duplicateWorkSchedule = false;
    this.editableRowIndex = index;
    this.selectedRow = JSON.parse(JSON.stringify(this.workschedulesList[index]));
  }

  cancelEdit(workSchedule: any) {
    if (this.selectedRow?.workScheduleCode !== workSchedule.workScheduleCode || this.selectedRow?.workScheduleDescription !== workSchedule.workScheduleDescription) {
      this.isConfirmPopup = 'edit';
    } else {
      this.editableRowIndex = -1;
      this.selectedRow = {};
    }
    this.duplicateWorkSchedule = false;
  }

  resetEditRow() {
    this.workschedulesList[this.editableRowIndex].workScheduleCode = this.selectedRow.workScheduleCode;
    this.workschedulesList[this.editableRowIndex].workScheduleDescription = this.selectedRow.workScheduleDescription;
    this.editableRowIndex = -1;
    this.selectedRow = {};
  }

  statusToggleForWorkSchedule(event: boolean, id: string) {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "id": id,
      "entityId": this.selectedEntity?.entityId,
      "status": event ? 'ACTIVE' : 'INACTIVE'
    };

    this.entitiesService.switchWorkScheduleStatus(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.workSchedules');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editWorkSchedule');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          'Edit',
          description)

        this.resetWorkSchedule();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  disableloader() {
    if (this.isLoader) { this.isLoader = false; }
    if (this.hexLoader) { this.hexLoader = false; }
  }

  onInputChange(fieldName: string, event: any) {
    this.fieldType = fieldName
    this.workScheduleSubject.next(event.target.value.trim() || '');
  }

  workScheduleCheck() {
    this.workScheduleSubject.pipe(debounceTime(500)).subscribe(keyword => {
      if (keyword !== '') {
        if (keyword === this.selectedRow.workScheduleCode) {
          return;
        } else {
          this.WorkScheduleUniqueCheckAPI(keyword);
        }
      }
    })
  }

  WorkScheduleUniqueCheckAPI(fieldName: any) {
    this.hexLoader = true;
    let body: any = {
      "id": this.selectedEntity?.entityId,
      "text": fieldName,
      "type": "code"
    }
    this.entitiesService.WorkScheduleDuplicateCheck(body).subscribe({
      next: (res) => {
        this.duplicateWorkSchedule = res.data;
        this.disableloader();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }
}
