import { StatusCodeService } from './../../../../services/status-code/status-code.service';
import { Component, Input, OnInit } from '@angular/core';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime } from 'rxjs';
import { auditTracking } from '../../../../../utils/audit-tracking';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../../services/shared/shared.service';

@Component({
    selector: 'app-cost-center',
    templateUrl: './cost-center.component.html',
    styleUrl: './cost-center.component.scss',
    standalone: false
})
export class CostCenterComponent implements OnInit {

  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  isLoader: boolean = true;
  hexLoader: boolean = false;
  costCentreIds: any = '';
  costCentreCode: any = '';
  costCentreDesc: any = '';
  listOfCostCentres: any[] = [];
  isConfirmPopup: string = '';
  editableRowIndex: number = -1;
  selectedRow: any = {};
  duplicateCostCentreCode: boolean = false;
  // duplicateCostCentreDesc: boolean = false;
  costCentreSubject = new Subject<any>();
  fieldType: string = '';

  constructor(private entitiesService: EntitiesService, private toastr: ToastrService, private showStatusCode: StatusCodeService,
    private translate: TranslateService, private sharedService: SharedService) {
    this.costcentreCheck();
  }

  ngOnInit() {
    this.sharedService.tracker();
    this.getCostCentre();
  }

  getCostCentre() {
    this.entitiesService.getCostCentre(this.selectedEntity?.entityId).subscribe({
      next: (res) => {
        if (res?.data) this.listOfCostCentres?.push(...res.data);
        this.disableloader();

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewCostCentre');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          description)

      },
      error: (error) => {
        this.disableloader();
      }
    })
  }

  saveUpdateCostCentre(costCentreDetails?: any) {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "costCentreId": costCentreDetails ? costCentreDetails?.costCentreId : null,
      "costCentreCode": costCentreDetails ? costCentreDetails.costCentreCode.trim() : this.costCentreCode.trim(),
      "costCentreDesc": costCentreDetails ? costCentreDetails.costCentreDesc.trim() : this.costCentreDesc.trim(),
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.saveUpdateCostCentre(body).subscribe({
      next: (res) => {
        this.hexLoader = false;
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', costCentreDetails ? 'common' : 'entities.costCentres');
        }
        if (costCentreDetails) {
          let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editCostCentre');

          auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
            this.translate.instant('auditTrail.entities.entityDetail'),
            'Edit',
            description)

        } else {
          let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.addCostCentre');

          auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
            this.translate.instant('auditTrail.entities.entityDetail'),
            'Add',
            description)
        }

        this.resetCostCentre();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  resetCostCentre() {
    if (this.editableRowIndex === -1) { this.costCentreCode = ''; }
    this.costCentreDesc = '';
    this.isLoader = true;
    this.listOfCostCentres = [];
    this.editableRowIndex = -1;
    this.duplicateCostCentreCode = false;
    // this.duplicateCostCentreDesc = false;
    this.getCostCentre();
  }

  deleteCostCentre() {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "costCentreId": this.costCentreIds,
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.deleteCostCentre(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.costCentres');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.deleteCostCentre');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          'Delete',
          description)

        this.resetCostCentre();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  onInputChange(fieldName: string, event: any) {
    this.fieldType = fieldName
    this.costCentreSubject.next(event.target.value.trim() || '');
  }

  costcentreCheck() {
    this.costCentreSubject.pipe(debounceTime(500)).subscribe(keyword => {
      if (keyword !== '') {
        if (this.fieldType === 'code' && (keyword === this.selectedRow?.costCentreCode)) {
          return;
        }
        else if (this.fieldType === 'desc' && (keyword === this.selectedRow?.costCentreDesc)) {
          return;
        } else {
          this.costcentreUniqueCheckAPI(keyword);
        }
      }
    })
  }

  costcentreUniqueCheckAPI(fieldName: any) {
    this.hexLoader = true;
    let body: any = {
      "id": this.selectedEntity?.entityId,
      "text": fieldName,
      "type": this.fieldType === "code" ? "code" : "desc"
    }
    this.entitiesService.duplicateCheckCostCentre(body).subscribe({
      next: (res) => {
        if (this.fieldType === "code") {
          this.duplicateCostCentreCode = res.data;
        } else if (this.fieldType === "desc") {
          // this.duplicateCostCentreDesc = res.data;
        }
        this.disableloader();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  inlineDelete(id?: string) {
    this.isConfirmPopup = 'delete';
    if (id) {
      this.costCentreIds = [id];
    } else {
      this.costCentreIds = this.listOfCostCentres.map(it => { return it.costCentreId });
    }
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.isConfirmPopup === 'edit') {
        this.resetEditRow();
      } else {
        this.deleteCostCentre();
      }
    }
    this.isConfirmPopup = '';
  }

  resetEditRow() {
    this.listOfCostCentres[this.editableRowIndex].costCentreCode = this.selectedRow?.costCentreCode;
    this.listOfCostCentres[this.editableRowIndex].costCentreDesc = this.selectedRow?.costCentreDesc;
    this.editableRowIndex = -1;
    this.duplicateCostCentreCode = false;
    // this.duplicateCostCentreDesc = false;
    this.selectedRow = {};
  }

  statusToggleForCostCentre(id: string, event: boolean) {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "id": id,
      "entityId": this.selectedEntity?.entityId,
      "status": event ? 'ACTIVE' : 'INACTIVE'
    }
    this.entitiesService.switchCostCentre(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.costCentres');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editCostCentre');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          'Edit',
          description)

        this.resetCostCentre();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  editRow(index: number) {
    this.duplicateCostCentreCode = false;
    // this.duplicateCostCentreDesc = false;
    this.editableRowIndex = index;
    this.selectedRow = JSON.parse(JSON.stringify(this.listOfCostCentres[index]));
  }

  cancelEdit(costCentre: any) {
    if (this.selectedRow?.costCentreCode !== costCentre.costCentreCode || this.selectedRow?.costCentreDesc !== costCentre.costCentreDesc) {
      this.isConfirmPopup = 'edit';
    } else {
      this.editableRowIndex = -1;
      this.duplicateCostCentreCode = false;
      // this.duplicateCostCentreDesc = false;
      this.selectedRow = {};

    }
  }

  disableloader() {
    if (this.isLoader) { this.isLoader = false; }
    if (this.hexLoader) { this.hexLoader = false; }
  }

}
