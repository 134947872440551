import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { SharedService } from '../../shared/services/shared/shared.service';

@Component({
    selector: 'app-unauth-access',
    templateUrl: './unauth-access.component.html',
    styleUrl: './unauth-access.component.scss',
    standalone: false
})
export class UnauthAccessComponent {
  constructor(private authService: AuthService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.tracker();
  }

  onLogout() { this.authService.logout(); }
}
