import { UserPreferencesService } from './shared/services/user-preferences/user-preferences.service';
import { AppService } from './app.service';
import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { AuthService } from './auth/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { auditTracking, initiateAuditTrackingUtil } from './utils/audit-tracking';
import { AzureService } from './shared/azure/azure.service';
import { CryptoService } from './shared/services/crypto/crypto.service';
import { SharedService } from './shared/services/shared/shared.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent {
  loggedIn: boolean = !!(localStorage.getItem('user-details') && JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? '')));
  loggedInSub: any;
  idleTimeoutCountDown: string = '';
  idleTimeoutPopup: boolean = false;
  url: string = '';
  tmfUserClients: boolean = false;
  hexLoader: boolean = false;
  elementRef: any;
  currentFocusedButton: any;
  token: any;

  // Restricts tab events on idleTimeoutPopup true
  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (this.idleTimeoutPopup && event.key === 'Tab') {
      this.preventTabNavigation(event);
    }
  }

  constructor(
    private translateService: TranslateService, private userPreferencesService: UserPreferencesService, private crypto: CryptoService, private sharedService: SharedService,
    private appService: AppService, private idle: Idle, private authService: AuthService, private router: Router, private azureService: AzureService,
    private translate: TranslateService) {
    this.setLoggedIn();
  }

  setLoggedIn() {
    this.loggedInSub = this.appService.getUserLoggedIn().subscribe((res) => {
      if (res) {
        this.loggedIn = res;
        if (this.loggedIn) {
          this.idleTimeoutSettings();
          this.languageSettings();
        }
      } else { this.tmfUserClients = false; }
    });
  }

  ngOnInit() {
    this.sharedService.tracker();
    /* Redirected from Email */
    this.emailRedirect();

    this.userClientsSubject();
    this.handleLoginLoader();
    this.languageSettings();

    if (this.loggedIn) { this.idleTimeoutSettings(); }
    // get current url
    this.routerEvents();
  }

  emailRedirect() {
    if (document.URL.indexOf("isRedirect") != -1) {
      window.localStorage.setItem("redirectUrl", decodeURIComponent(document.URL.split('#')[1]));
      this.token = window.localStorage.getItem("token");
      if (!this.token && !document.URL.includes('/logout')) {
        this.authService.getAuthenticationToken();
      }
    }
  }

  routerEvents() {
    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe((event: any) => {
      this.url = event.url;
      if (event instanceof NavigationEnd) {
        initiateAuditTrackingUtil(this.azureService, this.translateService, this.sharedService, this.url, true);
      }
    });
  }

  handleLoginLoader() { this.authService.showLoginLoader.subscribe(res => { this.hexLoader = res }); }

  userClientsSubject() { this.authService.userClientsSubject.subscribe(res => { this.tmfUserClients = res; }); }

  languageSettings() {
    // default on init
    this.updateLanguage(this.getDefaultLanguage());
    // on change of language in user settings
    this.userPreferencesService.userSettingsUpdatedSub.subscribe(res => {
      if (res) {
        this.updateLanguage(this.getDefaultLanguage());
        this.userPreferencesService.userSettingsUpdatedSub.next(null);
      }
    })
  }

  getDefaultLanguage() { return (localStorage.getItem('language') && localStorage.getItem('language')?.toLowerCase()) ?? 'en'; }

  updateLanguage(language: string) {
    this.translateService.setDefaultLang(language); // set default language
    this.translateService.use(language);
    document.documentElement.lang = language;
  }

  // idle timeout - idle time for 30 minutes then the app logs out
  idleTimeoutSettings() {
    this.idle.setIdle(60 * 18); // 18 minutes of inactivity before idle state
    this.idle.onIdleStart.subscribe(() => {
      this.idleTimeoutPopup = true;
      this.idle.clearInterrupts();
    });

    this.idle.setTimeout(60); // 1 seconds warning before timeout

    // timeout countdown
    this.idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.idleTimeoutCountDown = countdown + ' Seconds.';
    });

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document, note: exception is on mouse move
    this.idle.setInterrupts(
      [
        new EventTargetInterruptSource(document, 'click'),
        new EventTargetInterruptSource(document, 'keydown'),
        new EventTargetInterruptSource(document, 'mousemove'),
        new EventTargetInterruptSource(document, 'scroll'),
        new EventTargetInterruptSource(document, 'mousedown'),
        new EventTargetInterruptSource(document, 'mouseup')
      ]
    );

    // idle watch starts
    this.idle.watch();

    // idle timeout - executing logout
    this.idle.onTimeout.subscribe(() => {
      this.idleTimeoutPopup = false;
      this.onLogout();
    });

    // on idle ends
    this.idle.onIdleEnd.subscribe(() => {
      this.idleTimeoutPopup = false;
      this.resetIdle();
    });
  }

  onLogout() {
    this.currentFocusedButton = undefined;
    this.authService.logout();
  }

  resetIdle(event?: any) {
    this.currentFocusedButton = undefined;
    if (event) {
      auditTracking('', '',
        this.translate.instant('auditTrail.myWorkSpace'),
        this.translate.instant('auditTrail.continueSession'),
        this.translate.instant('auditTrail.continueSessionDesc'))
    }
    this.idle.watch();
    this.idle.stop();
    this.idleTimeoutSettings();
  }

  ngOnDestroy() {
    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
    if (this.loggedInSub) { this.loggedInSub.unsubscribe(); }
  }

  private preventTabNavigation(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    let idleTimeoutButtons1 = document.getElementsByClassName('idletimeout1');
    let idleTimeoutButtons2 = document.getElementsByClassName('idletimeout2');

    if (this.currentFocusedButton === idleTimeoutButtons1) {
      (idleTimeoutButtons2[0] as HTMLButtonElement).focus();
      if (this.currentFocusedButton.length !== undefined) this.currentFocusedButton[0].classList.remove('focus');
      this.currentFocusedButton = idleTimeoutButtons2;
      this.currentFocusedButton[0].classList.add('focus');
    } else {
      (idleTimeoutButtons1[0] as HTMLButtonElement).focus();
      if (this.currentFocusedButton !== undefined) this.currentFocusedButton[0].classList.remove('focus');
      this.currentFocusedButton = idleTimeoutButtons1;
      this.currentFocusedButton[0].classList.add('focus');
    }
  }
}
