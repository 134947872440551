import { SharedService } from './../../services/shared/shared.service';
import { UserPreferencesService } from './../../services/user-preferences/user-preferences.service';
import { UserPermissionsService } from './../../services/user-permissons/user-permissions.service';
import { Component } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CryptoService } from '../../services/crypto/crypto.service';
import { LoginPreferencesService } from '../../../modules/login-preferences/services/login-preferences.service';
import { TimezoneService } from '../../services/timezone/timezone.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { interval } from 'rxjs';
import { auditTracking } from '../../../utils/audit-tracking';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: false
})
export class HeaderComponent {
  url: string = '';
  userName: any = '';
  currentModule: string = '';
  showModules: boolean = false;
  userDetails: any = JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? ''));
  userPreference: boolean = false;
  userSettings: any;
  profilePic: any;
  clientLogo: string = '';
  notificationCount: number = 0;
  clientSubjectSub: any;
  userProfileSub: any;
  interval: any;

  constructor(private authService: AuthService, private router: Router, private userPermissionsService: UserPermissionsService, private translate: TranslateService,
    private crypto: CryptoService, private userPreferencesService: UserPreferencesService, private timezoneService: TimezoneService,
    private sharedService: SharedService, private loginPreferencesService: LoginPreferencesService, private appService: AppService) {
    this.clientSwitchUpdate();
    this.updateUserSettings();
  }

  ngOnInit() {
    this.sharedService.tracker();
    this.updateUserName();
    this.getClientLogo();
    this.getProfilePic();
    this.getGreetText();
    this.routerCheck();
    if (this.userDetails?.userType !== 'super_admin' && this.userDetails?.userType !== 'tmf_admin') {
      this.checkNotificationAlert();
    }
  }

  checkNotificationAlert() {
    if (!this.url?.includes('/preferences') && this.checkActionsForNotification()) {
      this.checkForFlashAlert();
      this.interval = interval(60000).subscribe(() => { this.checkForFlashAlert(); });
    }
  }

  clientSwitchUpdate() {
    this.clientSubjectSub = this.authService.updateCilentBasedSubject.subscribe(res => {
      if (res) {
        this.userDetails = JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? ''));
        this.getClientLogo();
      }
    });
  }

  updateUserSettings() {
    this.userProfileSub = this.userPreferencesService.userSettingsUpdatedSub.subscribe(res => {
      if (res) {
        this.getGreetText();
        this.updateUserName();
        this.getProfilePic();
      }
    })
  }

  getClientLogo() {
    this.userSettings = localStorage.getItem('user-settings') && JSON.parse(localStorage.getItem('user-settings') ?? '');
    if (this.userSettings?.logoContent) { this.clientLogo = this.sharedService.convertBase64ToImage(this.userSettings?.logoContent); }
  }

  updateUserName() {
    this.userDetails = localStorage.getItem('user-details') && JSON.parse(this.crypto.decrypt(localStorage.getItem('user-details') ?? ''));
    this.userName = this.userDetails?.preferredName ? this.userDetails?.preferredName : this.userDetails?.firstName;
  }

  getProfilePic() {
    this.userSettings = localStorage.getItem('user-settings') && JSON.parse(localStorage.getItem('user-settings') ?? '');
    if (this.userSettings?.settingValueJson?.userPicUrl && this.userSettings?.settingValueJson?.picArray) { this.profilePic = this.sharedService.convertBase64ToImage(this.userSettings?.settingValueJson?.picArray); }
    else { this.profilePic = null; }
  }

  routerCheck() {
    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe((event: any) => {
      this.url = event.url;
      this.currentModule = event.url.slice(1).indexOf('/') === -1 ? event.url.slice(1).substring(0, event.url.slice(1).length) :
        event.url.slice(1).substring(0, event.url.slice(1).indexOf('/'));

      if (this.currentModule === 'my-workspace') { this.getProfilePic(); this.updateUserName(); }

      if (this.interval && (this.url?.includes('/preferences') || this.url?.includes('/logout'))) { this.interval.unsubscribe(); }
      this.showModules = false;
    });
  }

  getFunctionality(functionality: string) { return this.userPermissionsService.isFunc(functionality); }

  disabledHome() { return this.currentModule.includes('my-workspace') || this.userDetails?.userType === 'super_admin' || this.userDetails?.userType === 'tmf_admin'; }

  getAdminAccess() {
    return this.getFunctionality('User Management') || this.getFunctionality('Role Management') || this.getFunctionality('Clients') ||
      this.userDetails?.userType === 'super_admin';
  }

  getWorkflowAccess() { return this.getFunctionality('Tickets') || this.getFunctionality('Tasks'); }

  handleAdminRouting(route: string) {
    if (this.userDetails?.userType === 'super_admin' || this.userDetails?.userType === 'tmf_admin') {
      if (this.router.url === '/entities' || this.router.url === '/employees' || this.router.url?.includes('/admin')) {
        if (route.includes('admin')) { this.adminRouting(); }
        else { this.router.navigate([route]); }
      } else {
        localStorage.setItem('top-nav-route', route);
        this.authService.adminUserCache();
      }
    } else {
      if (route.includes('admin')) { this.adminRouting(); }
      else { this.router.navigate([route]); }
    }
  }

  adminRouting() {
    if (this.getFunctionality('Clients')) { this.router.navigate(['/admin/clients']); }
    else if (this.getFunctionality('User Management') || this.getFunctionality('Role Management')) { this.router.navigate(['/admin/access']); }
  }

  handleClientRouting(route: string) {
    if (this.userDetails?.userType === 'super_admin' || this.userDetails?.userType === 'tmf_admin') {
      if (this.router.url === '/entities' || this.router.url === '/employees' || this.router.url?.includes('/admin')) {
        this.tmfUserClientsSwitch();
        localStorage.setItem('top-nav-route', route);
      } else { this.router.navigate([route]); }
    }
    else { this.router.navigate([route]); }
  }

  getGreetText() {
    let getHrs = this.timezoneService.convertTimeZone().getHours();
    if (getHrs < 12) { return this.translate.instant('header.goodMorning'); }
    else if (getHrs < 18) { return this.translate.instant('header.goodAfternoon'); }
    else { return this.translate.instant('header.goodEvening'); }
  }

  tmfUserClientsSwitch() { this.authService.userClientsSubject.next(true); }

  onLogout() { this.authService.logout(); }

  closeUserPreference() {
    this.userPreference = false;
  }

  resetUserPreference() {
    this.loginPreferencesService.resetUserPreference().subscribe({
      next: res => {
        this.loginPreferencesService.getUserPreferenceStatus().subscribe(res => {
          if (res?.data?.preferenceStatus === "In-progress") {
            this.loginPreferencesService.getCurrentPreferencesRoute(res?.data?.pageNo);
            setTimeout(() => {
              this.appService.setUserLoggedIn(false);
              window.localStorage.removeItem('user-details');
              // window.localStorage.removeItem('user-details-object');
              window.localStorage.removeItem('user-settings');
            }, 100);
          }
        });
      }, error: error => { }
    })
  }

  checkForFlashAlert() {
    this.sharedService.checkForFlashAlert().subscribe({
      next: res => {
        this.notificationCount = res.data ? res.data : 0;
      }, error: error => { }
    })
  }

  checkActionsForNotification() { return this.userPermissionsService.isActions('Notifications', 'View Notifications') }

  getFeatureLocalization(feature: string) {
    switch (feature) {
      case 'admin': return this.translate.instant('header.admin');
      case 'workflow': return this.translate.instant('workflow.workflow');
      case 'entities': return this.translate.instant('common.entities');
      case 'my workspace': return this.translate.instant('workspace.myWorkSpace');
      case 'notifications': return this.translate.instant('notifications.notifications');
      case 'employees': return this.translate.instant('common.employees');
      default: return;
    }
  }

  ngOnDestroy(): void {
    if (this.clientSubjectSub) { this.clientSubjectSub.unsubscribe(); }
    if (this.userProfileSub) { this.userProfileSub.unsubscribe(); }
    if (this.interval) { this.interval.unsubscribe(); }
  }
}
