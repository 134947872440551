<div class="services h100">
  <div class="title mb-2" translate>entities.services</div>

  <section>
    <ul class="section-header" [ngClass]="{'disabled no-opacity' : !getActions('Create Entity')}">
      <div *ngIf="!isLoader">
        <li *ngFor="let serviceName of listOfServiceLine; let i = index"
          [ngClass]="{'active fnt-md': selectedServiceLine === listOfServiceLine[i]}" (click)="checkFormChanges(i);">
          <span>
            {{serviceName.serviceLineName}}</span>
        </li>
        <li (click)="checkFormChanges(-1,'serviceRequest')" [ngClass]="{'active fnt-md' : serviceRequest}">Services</li>
      </div>

      <button *ngIf="!isLoader" class="btn-link" (click)="updateContract()"
        [ngClass]="{'disabled' : disableEdit || !getActions('Manage Service Configuration')}"
        translate>entities.syncContract</button>
      <ngx-skeleton-loader [count]="1" *ngIf="isLoader"></ngx-skeleton-loader>
    </ul>

    <!-- add or delete all service -->
    <div class="modify-service" [ngClass]="{'disabled no-opacity' : disableEdit}"
      *ngIf="(modifyServices || noL2Services()) && !serviceRequest">
      <button class="btn-link me-2" [ngClass]="{'disabled' : !getActions('Manage Service Configuration')}"
        (click)="addNewContract=true;"><i class="icon-create"></i><span translate>common.addNew</span></button>
      <button class="btn-link delete red"
        [ngClass]="{'disabled' : !noL2Services() || !getActions('Manage Service Configuration')}"
        (click)="confirmPopup='deleteAll'" translate><i class="icon-bin"></i>common.deleteAll</button>
    </div>

    <div class="service-select" [ngClass]="{'add-contract' : addNewContract}">

      <!-- service line dropdown-->
      <div class="form-group check-select me-4" *ngIf="serviceRequest">
        <span class="fnt-light pe-2" translate>entities.approvals.serviceLine</span>
        <ng-multiselect-dropdown focusOnClick class="full-border multi-select sm" [settings]="serviceLineSettings"
          [placeholder]="'common.select' | translate" [(ngModel)]="serviceLineDetails" [data]="listOfServiceLine"
          (onSelect)="serviceLineSelect()" (onDeSelect)="onDeselectServiceLine()"></ng-multiselect-dropdown>
      </div>

      <!-- contract dropdown -->
      <div class="form-group check-select me-4" *ngIf="addNewContract">
        <span class="fnt-light pe-2" translate>entities.contractId</span>
        <ng-multiselect-dropdown focusOnClick class="full-border multi-select sm"
          [settings]="serviceRequest ? serviceRequestContractSettings : contractSettings"
          [placeholder]="'common.select' | translate" [(ngModel)]="contractDetails" [data]="contractList"
          (onSelect)="fetchServices()" (onDeSelect)="onDeselectContract()"></ng-multiselect-dropdown>
      </div>

      <!-- service dropdown -->
      <div class="form-group me-4 serviceDropdown" *ngIf="addNewContract" [ngClass]="{'check-select':serviceRequest}">
        <span class="fnt-light pe-2" translate>entities.serviceId</span>
        <ng-multiselect-dropdown focusOnClick class="full-border multi-select sm"
          [settings]="serviceRequest ? serviceRequestSettings: serviceSettings"
          [placeholder]="'common.select' | translate" [data]="serviceList" [(ngModel)]="serviceArray"
          (onSelect)="onSelectService()" (onSelectAll)="onSelectAllService()" (onDeSelectAll)="onDeselectAllService()"
          (onDeSelect)="onDeselectService()">
        </ng-multiselect-dropdown>
      </div>

      <button *ngIf="getActions('Manage Service Configuration') && addNewContract" class="btn-primary fnt-light sm px-3"
        [ngClass]="{'disabled': disableEdit || serviceDetails.length === 0}" (click)="saveService()"
        translate>common.apply</button>
    </div>

    <!-- section body -->
    <div class="section-body">
      <div class="h100" [ngClass]="{'disabled no-opacity' : disableEdit}" *ngIf="!serviceRequest">
        <div class="no-services flex-center flex-column" *ngIf="noDataCheck()">
          <h5 style="font-weight: 300;" translate> <strong class="fnt-md">0&nbsp;</strong>entities.l1Services</h5>
          <p><span translate>entities.selectContractAndserviceId</span></p>
        </div>

        <div class="list-section" *ngFor="let item of contractServiceDetails">
          <ng-container>
            <div class="list-header">
              <div class="d-flex">
                <div>
                  <span translate>entities.contractId</span>: <span class="fnt-md pe-3">{{item.contractName}}</span>
                </div>
                <div>
                  <span translate>entities.serviceId</span>: <span class="fnt-md">{{item.serviceName}}</span>
                </div>
              </div>
              <div class="options" *ngIf="getActions('Manage Service Configuration') ">
                <div class="dropdown">
                  <span class="dropdown-toggle" data-bs-toggle="dropdown"><i class="icon-options"></i></span>
                  <div class="dropdown-menu options-menu" style="width: 150px;">
                    <a href="javascript:void(0)" (click)="inlineDelete(item)" class="dropdown-item" translate>
                      <i class="icon-bin"></i>common.delete</a>
                  </div>
                </div>
              </div>
            </div>
            <ul *ngFor="let service of item.l1ServiceList">
              <div class="sub-header"> {{service.l1ServiceName}}&nbsp;({{service.count}})</div>
              <li *ngFor="let servicename of service.l2ServiceList">
                <app-checkbox *ngIf="servicename.isActive" [checked]="!servicename.isDisabled"
                  (onClick)="enableDisableL2Service($event,item,servicename)"></app-checkbox>
                <span class="l2-service"
                  [ngClass]="{'disabled ps-0' : !servicename.isActive}">{{servicename.l2ServiceName}}</span>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>

      <div class="h100" [ngClass]="{'disabled no-opacity' : disableEdit}" *ngIf="serviceRequest">
        <div class="no-services flex-center flex-column" *ngIf="!hexLoader && !noServicesRequest()">
          <h5 style="font-weight: 300;" translate>common.nodata</h5>
        </div>
        <div class="list-section" *ngFor="let item of serviceRequestDetails; let i = index">
          <ng-container *ngFor="let serviceDetail of item.enateTicketContractService">
            <div class="list-header">
              <div class="d-flex">
                <div>
                  <span translate>entities.approvals.serviceLine</span>: <span class="fnt-md pe-3">{{
                    item.serviceLineName }}</span>
                </div>
                <div>
                  <span translate>entities.contractId</span>: <span class="fnt-md pe-3">{{ serviceDetail.contractName
                    }}</span>
                </div>
                <div>
                  <span translate>entities.serviceId</span>:
                  <span class="fnt-md">{{ serviceDetail.serviceName }}</span>
                </div>
              </div>
              <div class="options" *ngIf="getActions('Manage Service Configuration')">
                <div class="dropdown">
                  <span class="dropdown-toggle" data-bs-toggle="dropdown">
                    <i class="icon-options"></i>
                  </span>
                  <div class="dropdown-menu options-menu" style="width: 150px;">
                    <a href="javascript:void(0)" (click)="inlineDeleteTicket(item.serviceLineId, serviceDetail)"
                      class="dropdown-item" translate>
                      <i class="icon-bin"></i>common.delete
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ul>
              <li *ngFor="let serviceTicketName of serviceDetail.l2Service">
                <span class="l2-service ps-0">{{ serviceTicketName.l2ServiceName }}</span>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</div>

<app-hex-loader *ngIf="hexLoader"></app-hex-loader>

<app-confirmation-popup *ngIf="confirmPopup"
  [description]="confirmPopup === 'deleteAll' ? 'common.confirmDeleteAll' : 'common.confirmDelete'"
  (emitEvent)="handleConfirmation($event)"></app-confirmation-popup>