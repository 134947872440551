
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StatusCodeService } from '../../../../services/status-code/status-code.service';
import { auditTracking } from '../../../../../utils/audit-tracking';
import { SharedService } from '../../../../services/shared/shared.service';

@Component({
    selector: 'app-create-pay-element',
    templateUrl: './create-pay-element.component.html',
    styleUrls: ['./create-pay-element.component.scss'],
    standalone: false
})
export class CreatePayElementComponent implements OnInit {
  @Output() closeCreatePayElement = new EventEmitter();
  @Output() refreshPayElements = new EventEmitter();
  @Input() selectedEntity: any;
  elementCodeItems: [] = [];
  wageGroupItems: [] = [];
  elementTypeItems: [] = [];
  hexLoader: boolean = false;
  payElementObj: any;
  isEditing: boolean = false;
  showEdit: boolean = false;
  payElementForm!: FormGroup
  searchSubject = new Subject<string>();
  payElementsUniqueCheckBody = {
    isPayElementCodeCheck: false,
    isEnglishNameCheck: false,
    isLocalNameCheck: false,
    name: ''
  };

  elementCodeSettings: IDropdownSettings = {
    selectAllText: 'Select All', enableCheckAll: false, searchPlaceholderText: 'Search...', clearSearchFilter: true, closeDropDownOnSelection: true,
    idField: 'payElementCodeId', textField: 'code', allowSearchFilter: true, singleSelection: true,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata')
  };

  wageGroupSettings: IDropdownSettings = {
    selectAllText: 'Select All', enableCheckAll: false, searchPlaceholderText: 'Search...', clearSearchFilter: true, closeDropDownOnSelection: true,
    idField: 'wageGroupId', textField: 'wageGroupName', allowSearchFilter: true, singleSelection: true,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata')
  };

  elementTypeSettings: IDropdownSettings = {
    selectAllText: 'Select All', enableCheckAll: false, searchPlaceholderText: 'Search...', clearSearchFilter: true, closeDropDownOnSelection: true,
    idField: 'payElementTypeId', textField: 'elementTypeCode', allowSearchFilter: true, singleSelection: true,
    noDataAvailablePlaceholderText: this.translate.instant('common.nodata'), noFilteredDataAvailablePlaceholderText: this.translate.instant('common.nodata')
  };

  constructor(private toastr: ToastrService, private entitiesService: EntitiesService, private formBuilder: FormBuilder,
    private translate: TranslateService, private statusCodeService: StatusCodeService, private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.sharedService.tracker();
    this.payElementForm = this.formBuilder.group({
      payElementCode: [[], [Validators.required]],
      localLanguageName: ['', [Validators.required]],
      englishLanguageName: ['', [Validators.required,]],
      wageGroup: [[], [Validators.required]],
      elementType: [[], [Validators.required]],
      importClient: ['1', [Validators.required]]
    });

    this.getWageGroups();
    this.getPayElementTypes();
  }

  getWageGroups() {
    this.entitiesService.getWageGroups().subscribe({
      next: (res) => {
        this.wageGroupItems = res.data;
      },
      error: (error) => { }
    })
  }

  getPayElementTypes() {
    this.entitiesService.getPayElementTypes().subscribe({
      next: (res) => {
        this.elementTypeItems = res.data;
      },
      error: (error) => { }
    })
  }

  savePayElement() {
    if (this.payElementForm.valid) {
      this.hexLoader = true;
      let payElementObj = {
        entityId: this.selectedEntity?.entityId,
        elementCode: this.payElementForm.get('payElementCode')?.value?.trim(),
        localName: this.payElementForm.value.localLanguageName?.trim(),
        englishName: this.payElementForm.value.englishLanguageName?.trim(),
        wageGroup: this.payElementForm.get('wageGroup')?.value,
        elementType: this.payElementForm.get('elementType')?.value,
        isClientImport: this.payElementForm.value.importClient && JSON.parse(this.payElementForm.value.importClient),
        isDeleted: false
      };
      this.entitiesService.savePayElement(payElementObj).subscribe({
        next: (res) => {

          this.refreshPayElements.emit();
          this.hexLoader = false;
          // audit tracking 
          const desc = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.addPayElement') + '[' + payElementObj?.elementCode + ']';
          auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
            this.translate.instant('auditTrail.entities.entityDetail'),
            'Add',
            desc)
          if (res?.statusCode === 'COBALT_002_017' || res?.statusCode === 'UNAUTHORIZED_USER') {
            this.statusCodeService.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.statusCodeService.displayToastrMessage(res?.statusCode, 'success', 'entities');
          }
        },
        error: (error) => {
          this.hexLoader = false;
        }
      });
    } else {
      this.payElementForm.markAllAsTouched();
    }
  }

  updatePayElementForm(payElement: any) {
    this.payElementForm.patchValue({
      payElementCode: payElement.elementCode,
      localLanguageName: payElement.localLanguageName,
      englishLanguageName: payElement.englishLanguageName,
      wageGroup: payElement.wageGroup,
      elementType: payElement.elementType,
      importClient: payElement.isClientImport
    });
  }

  handleCancel() {
    if (this.isEditing) {
      this.showEdit = true;
      this.isEditing = false;
      this.payElementForm.reset();
      this.updatePayElementForm(this.payElementObj);
    } else {
      this.closeCreatePayElement.emit();
    }
  }

  // trimPayElementCode() {
  //   if (this.payElementForm.value.payElementCode) {
  //     if (!this.payElementForm.value.payElementCode?.trim()) {
  //       this.payElementForm.get('payElementCode')?.setErrors({ required: true });
  //       this.payElementsUniqueCheckBody = { ...this.payElementsUniqueCheckBody, isPayElementCodeCheck: false }
  //     } else {
  //       this.payElementsUniqueCheckBody = {
  //         name: this.payElementForm.value.payElementCode.trim(),
  //         isPayElementCodeCheck: true,
  //         isLocalNameCheck: false,
  //         isEnglishNameCheck: false
  //       }
  //       this.searchSubject.next(this.payElementForm.value.payElementCode);
  //     }
  //   }
  // }

  // trimLocalLanguage() {
  //   if (this.payElementForm.value.localLanguageName) {
  //     if (!this.payElementForm.value.localLanguageName?.trim()) {
  //       this.payElementForm.get('localLanguageName')?.setErrors({ required: true });
  //       this.payElementsUniqueCheckBody = { ...this.payElementsUniqueCheckBody, isLocalNameCheck: false }
  //     } else {
  //       this.payElementsUniqueCheckBody = {
  //         name: this.payElementForm.value.localLanguageName.trim(),
  //         isLocalNameCheck: true,
  //         isPayElementCodeCheck: false,
  //         isEnglishNameCheck: false
  //       }
  //       this.searchSubject.next(this.payElementForm.value.localLanguageName);
  //     }
  //   }
  // }

  // trimEnglishLanguage() {
  //   if (this.payElementForm.value.englishLanguageName) {
  //     if (!this.payElementForm.value.englishLanguageName?.trim()) {
  //       this.payElementForm.get('englishLanguageName')?.setErrors({ required: true });
  //       this.payElementsUniqueCheckBody = { ...this.payElementsUniqueCheckBody, isEnglishNameCheck: false }
  //     } else {
  //       this.payElementsUniqueCheckBody = {
  //         name: this.payElementForm.value.englishLanguageName.trim(),
  //         isEnglishNameCheck: true,
  //         isLocalNameCheck: false,
  //         isPayElementCodeCheck: false,
  //       }
  //       this.searchSubject.next(this.payElementForm.value.englishLanguageName);
  //     }
  //   }
  // }

  // payElementsUniqueCheck() {
  //   this.hexLoader = true;
  //   this.entitiesService.payElementsUniqueCheck({ ...this.payElementsUniqueCheckBody, entityId: this.selectedEntity?.entityId }).subscribe({
  //     next: res => {
  //       if (res.data) {
  //         if (res.data?.payElementCodeDuplicate) { this.payElementForm.get('payElementCode')?.setErrors({ payElementCodeDuplicate: true }); }
  //         else if (res.data?.localNameDuplicate) { this.payElementForm.get('localLanguageName')?.setErrors({ localNameDuplicate: true }); }
  //         else if (res.data?.englishNameDuplicate) { this.payElementForm.get('englishLanguageName')?.setErrors({ englishNameDuplicate: true }); }
  //       }
  //       this.hexLoader = false;
  //     }, error: error => {
  //       this.hexLoader = false;
  //     }
  //   })
  // }
}