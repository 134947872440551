import { Component } from '@angular/core';
import { SharedService } from '../../shared/services/shared/shared.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: false
})
export class LogoutComponent {
    constructor(private sharedService: SharedService) { }
    
    ngOnInit(): void {
      this.sharedService.tracker();
    }
}
