import { Component, Input, OnInit } from '@angular/core';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { ToastrService } from 'ngx-toastr';
import { StatusCodeService } from '../../../../services/status-code/status-code.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, debounceTime } from 'rxjs';
import { auditTracking } from '../../../../../utils/audit-tracking';
import { SharedService } from '../../../../services/shared/shared.service';
@Component({
    selector: 'app-pay-group',
    templateUrl: './pay-group.component.html',
    styleUrl: './pay-group.component.scss',
    standalone: false
})
export class PayGroupComponent implements OnInit {
  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  isLoader: boolean = true;
  hexLoader: boolean = false;
  payGroup: any = '';
  payGroupList: any[] = [];
  isConfirmPopup: string = '';
  payGroupId: any = '';
  editableRowIndex: number = -1;
  selectedRow: any = {};
  duplicatePayGroup: boolean = false;
  payGroupCheckSubject = new Subject<any>();

  constructor(private entitiesService: EntitiesService, private toastr: ToastrService, private showStatusCode: StatusCodeService, private translate: TranslateService, private sharedService: SharedService) {
    this.payGroupCheck();
  }
  ngOnInit(): void {
    this.sharedService.tracker();
    this.getPayGroup();
  }

  getPayGroup() {
    this.entitiesService.getPayGroup(this.selectedEntity?.entityId).subscribe({
      next: (res) => {
        if (res?.data) this.payGroupList?.push(...res.data);
        this.disableloader();

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.viewPayGroup');
        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          this.translate.instant('auditTrail.auditTypeView'),
          description)

      },
      error: (error) => {
        this.disableloader();
      }
    })
  }

  saveUpdatePayGroup(payGroupDetails?: any) {
    if (!this.duplicatePayGroup) {
      this.toastr.clear();
      this.hexLoader = true;
      let body: any = {
        "payGroupId": payGroupDetails ? payGroupDetails?.payGroupId : null,
        "payGroupName": payGroupDetails ? payGroupDetails.payGroupName.trim() : this.payGroup.trim(),
        "entityId": this.selectedEntity?.entityId
      }
      this.entitiesService.saveAndUpdatePayGroup(body).subscribe({
        next: (res) => {
          this.hexLoader = false;
          if (res?.statusCode === 'UNAUTHORIZED_USER') {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
          } else {
            this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', payGroupDetails ? 'common' : 'entities.payGroups');
          }
          if (payGroupDetails) {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editPayGroup');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              'Edit',
              description)

          } else {
            let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.addPayGroup');

            auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
              this.translate.instant('auditTrail.entities.entityDetail'),
              'Add',
              description)
          }

          this.resetPayGroup();
        },
        error: (error) => {
          this.hexLoader = false;
        }
      })
    }
  }

  resetPayGroup() {
    if (this.editableRowIndex === -1) { this.payGroup = '' };
    this.isLoader = true;
    this.payGroupList = [];
    this.editableRowIndex = -1;
    this.duplicatePayGroup = false;
    this.getPayGroup();
  }

  deletePayGroup() {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "payGroupId": [this.payGroupId],
      "entityId": this.selectedEntity?.entityId
    }
    this.entitiesService.deletePayGroup(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.payGroups');
          this.hexLoader = false;
        }

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.deletePayGroup');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          'Delete',
          description)

        this.resetPayGroup();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  inlineDelete(id: any) {
    this.isConfirmPopup = 'delete';
    this.payGroupId = id;
  }

  handleConfirmation(ev: boolean) {
    if (ev) {
      if (this.isConfirmPopup === 'edit') {
        this.resetEditRow();
      } else {
        this.deletePayGroup();
      }
    }
    this.isConfirmPopup = '';
    this.duplicatePayGroup = false;
  }

  resetEditRow() {
    this.payGroupList[this.editableRowIndex].payGroupName = this.selectedRow?.payGroupName;
    this.editableRowIndex = -1;
    this.selectedRow = {};
  }

  statusToggleForPayGroup(event: boolean, id: string) {
    this.toastr.clear();
    this.hexLoader = true;
    let body: any = {
      "id": id,
      "entityId": this.selectedEntity?.entityId,
      "status": event ? 'ACTIVE' : 'INACTIVE'
    }
    this.entitiesService.switchPayGroup(body).subscribe({
      next: (res) => {
        if (res?.statusCode === 'UNAUTHORIZED_USER') {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'error', 'entities');
        } else {
          this.showStatusCode.displayToastrMessage(res?.statusCode, 'success', 'entities.payGroups');
        }
        this.hexLoader = false;

        let description = '[' + this.selectedEntity?.entityName + ']' + this.translate.instant('auditTrail.entities.editPayGroup');

        auditTracking(this.selectedEntity?.clientName, this.selectedEntity?.entityName,
          this.translate.instant('auditTrail.entities.entityDetail'),
          'Edit',
          description)

        this.resetPayGroup();
      },
      error: (error) => {
        this.hexLoader = false;
      }
    })
  }

  editRow(index: number) {
    this.duplicatePayGroup = false;
    this.editableRowIndex = index;
    this.selectedRow = JSON.parse(JSON.stringify(this.payGroupList[index]));
  }

  cancelEdit(payGroup: any) {
    if (this.selectedRow.payGroupName !== payGroup.payGroupName) {
      this.isConfirmPopup = 'edit';
    } else {
      this.editableRowIndex = -1;
      this.selectedRow = {};
    }
    this.duplicatePayGroup = false;
  }

  disableloader() {
    if (this.isLoader) { this.isLoader = false; }
    if (this.hexLoader) { this.hexLoader = false; }
  }

  onInputChange(event: any) {
    this.duplicatePayGroup = false;
    this.payGroupCheckSubject.next(event.target.value.trim() || '');
  }

  payGroupCheck() {
    this.payGroupCheckSubject.pipe(debounceTime(500)).subscribe(
      keyword => {
        if (keyword != '') {
          if (keyword === this.selectedRow?.payGroupName) {
            return;
          }
          else {
            this.paygroupUniqueCheckAPI(keyword);
          }
        }
      }
    )
  }

  paygroupUniqueCheckAPI(payGroupName: any) {
    this.hexLoader = true;
    this.entitiesService.uniqueCheckPayGroup(this.selectedEntity?.entityId, payGroupName).subscribe({
      next: res => {
        this.duplicatePayGroup = res.data;
        this.hexLoader = false;
      }, error: error => {
        this.hexLoader = false;
      }
    })
  }
}