import { Component, Input } from '@angular/core';
import { UserPermissionsService } from '../../../services/user-permissons/user-permissions.service';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
    selector: 'app-custom-values',
    templateUrl: './custom-values.component.html',
    styleUrl: './custom-values.component.scss',
    standalone: false
})
export class CustomValuesComponent {
  @Input() selectedEntity: any;
  @Input() disableEdit: boolean = false;
  selectedTab: string = 'Job Band';

  constructor( private userPermissionsService: UserPermissionsService, private sharedService: SharedService){ }

  ngOnInit() {
    this.sharedService.tracker();
  }

  getActions(action: string) { return this.userPermissionsService.isActions('Entities', action); }
}
