import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { Component } from '@angular/core';
import { SharedService } from '../../shared/services/shared/shared.service';

@Component({
    selector: 'app-activate-user',
    templateUrl: './activate-user.component.html',
    styleUrl: './activate-user.component.scss',
    standalone: false
})
export class ActivateUserComponent {
  infoMesssage: string = '';
  hexLoader: boolean = false;

  constructor(private authService: AuthService, private router: Router, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.tracker();
  }

  activateUser() {
    this.hexLoader = true;
    let token = this.router.url.split('=')[1];

    this.authService.activateUser(token).subscribe({
      next: res => {
        this.infoMesssage = res?.message;
        this.hexLoader = false;
      },
      error: error => {
        this.infoMesssage = error?.error?.message;
        this.hexLoader = false;
      }
    })
  }

}
