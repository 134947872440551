import { Component } from '@angular/core';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
    selector: 'app-payruns',
    templateUrl: './payruns.component.html',
    styleUrl: './payruns.component.scss',
    standalone: false
})
export class PayrunsComponent {
    constructor(private sharedService: SharedService) { }
    
    ngOnInit() {
      this.sharedService.tracker();
    }
}
