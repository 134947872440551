import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Notification } from '../../../core/constants/endpoint';
import { jwtDecode } from 'jwt-decode';
import { auditTracking } from '../../../utils/audit-tracking';
import * as ExcelJS from 'exceljs';

interface DecodedToken {
  email?: string;
  exp?: number;
  iat?: number;
  iss?: string;
  sub?: string;
  tracingId?: string;
  nts?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  entityData: any;
  ssoLinkReg = /^(?!\s)(?!-)(?!.*--)([a-z0-9-]{1,63}\.)+[a-z0-9-]{2,63}$/i;
  emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  selectedDropdownItem = new BehaviorSubject<{}>({});
  private tokenKey = localStorage.getItem('token');
  private decodedToken: DecodedToken | null = null;

  constructor(private translate: TranslateService, private http: HttpClient) {
    if (this.tokenKey) {
      this.setToken(this.tokenKey);
    }
  }

  async exportToExcel(columns: any, rows: any, fileName: string, auditActionDesc?: any, auditActionFile?: any, activityId?: any, entityName?: any) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(fileName);

    // Sample data
    const excelData = [
      columns,
      ...rows.map((row: any) =>
        Object.keys(rows[0]).map((column: any) => String(row[column as keyof typeof row] ?? ''))
      )
    ];

    // Add rows
    excelData.forEach(item => {
      worksheet.addRow(item);
    });

    // Export the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);

    // audit tracking
    let description = (activityId ? ('[' + activityId + '] ') : '') + this.translate.instant(auditActionDesc) + `[${fileName}.xlsx]`;
    auditTracking('', entityName ? entityName : '',
      this.translate.instant(auditActionFile),
      'Export',
      description, activityId ? activityId : '');
  }

  convertBase64ToImage(base64: any) {
    const blob = new Blob([new Uint8Array(atob(base64).split('').map(char => char.charCodeAt(0)))], { type: 'image/png' });
    // Create a new URL object from the Blob object.
    return URL.createObjectURL(blob);
  }

  formatDateToView(date: any) { return moment.utc(date).format('DD MMM YYYY'); }

  // Add 'done' button on dropdown list
  addDoneButton(listOfDropDowns: any, footerClass: string) {
    let dropDownList = document.querySelectorAll(`${footerClass} .dropdown-list`);
    dropDownList.forEach((element, index) => {
      const btnDone = document.createElement("button");
      btnDone.classList.add('done-button');
      btnDone.textContent = this.translate.instant('entities.done');
      btnDone.addEventListener("click", () => this.doneButtonClick(index, listOfDropDowns, footerClass), false);
      btnDone.classList.add(listOfDropDowns[index]);
      element.appendChild(btnDone);
    });
  }

  removeDoneButton(footerClass: string) {
    let dropDownList = document.querySelectorAll(`${footerClass} .dropdown-list`);

    dropDownList.forEach((element) => {
      if (element.lastElementChild) { element.removeChild(element.lastElementChild) };
    });
  }

  addDoneButtonWorkFlow(dropdownListArray: any, footerClass: string) {
    const valueList = dropdownListArray.map((item: any) => item.id);
    let dropDownList = document.querySelectorAll(`${footerClass} .dropdown-list`);
    dropDownList.forEach((element, index) => {
      if (!element.lastElementChild?.classList.contains('done-button')) {
        const btnDone = document.createElement("button");
        btnDone.classList.add('done-button');
        btnDone.textContent = this.translate.instant('entities.done');
        btnDone.addEventListener("click", () => this.doneButtonClick(index, valueList, footerClass), false);
        btnDone.classList.add(valueList[index]);
        const showOrNot = dropdownListArray.find((item: any) => item.id === valueList[index]);
        showOrNot.showDone && element.appendChild(btnDone);
      }
    });
  }

  doneButtonClick(index: number, listOfDropDowns: any, footerClass: any) {
    const classList = listOfDropDowns;
    const selectedClass = classList[index];
    this.selectedDropdownItem.next({
      'selectedClass': selectedClass,
      'module': footerClass
    });
  }

  openDatePicker() {
    // Show a date picker
    setTimeout(() => {
      const inputDateElement = document.querySelector('input[type="date"]') as HTMLInputElement;
      if (inputDateElement?.classList?.contains('editable')) {
        inputDateElement.click();
        inputDateElement.showPicker();
      }
    }, 10);
  }
  setEntityData(data: any) {
    this.entityData = data;
  }
  getEntityData() {
    return this.entityData;
  }

  saveFile(blob: Blob, fileName: string): void {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  convertBytes(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  checkForScroll(tbodyRef: any): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        const tbody = tbodyRef?.nativeElement;
        resolve(tbody ? tbody.scrollHeight > tbody.clientHeight : false);
      }, 0);
    });
  }

  checkFileType(fileType: string): boolean {
    const supportedExtensions = ['ppt', 'pptx', 'xls', 'xlsx', 'doc', 'docx', 'pdf', 'txt', 'rtf', 'jpg', 'jpeg', 'png', 'bmp', 'ods', 'odt', 'zip', '7z', 'csv', 'msg'];
    const ext = fileType.split('.').pop()?.toLowerCase();
    return ext ? supportedExtensions.includes(ext) : false;
  }

  getFileSize(fileSize: number): boolean {
    return fileSize <= 104857600;
  }

  getFileIcon(fileType: string): string {
    switch (fileType.toLowerCase()) {
      case 'pdf':
        return './assets/images/pdf.png';
      case 'xlsx':
      case 'xls':
      case 'csv':
        return './assets/images/excel.png';
      case 'docx':
      case 'doc':
        return './assets/images/word.png';
      case 'ppt':
      case 'pptx':
        return './assets/images/power-point.png';
      case 'txt':
      case 'msg':
        return './assets/images/icon-file.png';
      case 'zip':
      case '7z':
        return './assets/images/icon-zip.png';
      case 'jpg':
      case 'jpeg':
      case 'png':
        return './assets/images/icon-png.png';
      default:
        return './assets/images/icon-file-default.png';
    }
  }
  checkForFlashAlert(): Observable<any> {
    return this.http.get(environment.notification_url + Notification.checkForFlashAlert);
  }

  setToken(token: string): void {
    try {
      if (token != null && token != undefined) {
        this.decodedToken = jwtDecode<DecodedToken>(token);
      }
    } catch (error) {
      this.decodedToken = null;
    }
  }

  getDecodedToken(): DecodedToken | null {
    return this.decodedToken;
  }

  tracker() {
    if (environment.production) {
    let script = document.createElement('script');
    script.type = `text/javascript`;
    script.src = "https://dok.js-cdn.dynatrace.com/jstag/190ca06e580/bf13580jca/5eaf2f61d1c6add_complete.js";
    document.getElementsByTagName('body')[0]?.appendChild(script);
    }
  }

}
